
    @if(registered || data.confirm_mode){
      <form class="form-confirm" [formGroup]="formConfirm" (submit)="confirm()" method="post">
        <div style="padding-bottom: 1em;">
          <p style="font-family: Montserrat; ">
            Please enter the code sent to your email to confirm your account or resend another one
          </p>
          <div style="display: flex; flex-wrap: wrap; gap: 0.5em;" id="codes-container">
            @for(c of codes ; track $index){
              <input (click)="cleanCode($index)" (paste)="pasteCode($event)" (input)="validateCode($event,$index)" class="code-class" type="number" formControlName="code{{$index}}">
            }

            <button type="button" (click)="resend()" mat-icon-button matToolTip="Resend code">
              <mat-icon>restart_alt</mat-icon>
            </button>
          </div>
          
          <mat-error style="font-family: Montserrat; color: var(--mon-red);">
            {{errorConfirm}}
          </mat-error>

          @if(confirmed){
            <p style="font-family: Montserrat;">
              Your account has been successfully verified. You can now <a (click)="emailVerification.emit(true)" style="cursor: pointer; color: var(--mon-red); text-decoration: none;">log in</a>
            </p>
          }
          <button  style="margin-top: 1em;" [disabled]="disabledConfirm" mat-button class="confirm-button" type="submit">
            Confirm
          </button>
        </div>
      </form>
    }@else {
      <form [formGroup]="formRegister" (submit)="register()" method="post">
        <div style="display: flex; flex-wrap: wrap; justify-content: space-between; gap: 1em;">
          <mat-form-field style="width: 48%; flex-grow:1;">
            <mat-label>First name</mat-label>
            <input formControlName="first_name" type="text" matInput  />
            @if(formRegister.get('first_name')?.hasError){
              <mat-error style="font-family: Montserrat;">
                {{getError('first_name')}}
              </mat-error>
            }
            
          </mat-form-field>
          <mat-form-field style="width: 48%; flex-grow:1;">
            <mat-label>Last name</mat-label>
            <input formControlName="last_name" type="text" matInput  />
            @if(formRegister.get('last_name')?.hasError){
              <mat-error style="font-family: Montserrat;">
                {{getError('last_name')}}
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div style="width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; gap: 1em;">
          <div style="width: 48%; min-width: 183px; flex-grow:1;">
            <mat-form-field style="width: 100%;">
              <mat-label (click)="picker.open()">Birth date</mat-label>
              <input readonly (click)="picker.open()" style="cursor: default;" formControlName="birthdate" matInput [matDatepicker]="picker">
              <mat-hint style="font-family: Montserrat;">{{getHint()}}</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker startView="multi-year" #picker></mat-datepicker>
              @if(formRegister.get('birthdate')?.hasError){
                <mat-error style="font-family: Montserrat;">
                  {{getError('birthdate')}}
                </mat-error>
              }
            </mat-form-field>
            
            <mat-form-field class="gender-form-field" style="width: 100%; margin-top: 0.5em;">
              <input formControlName="gender" matInput hidden>
              <mat-button-toggle-group formControlName="gender" 
              style="width: 100%;  border-radius: 4px; border-color: white; background-color: var(--mon-register-color);"
              name="gender" aria-label="Gender">
                <mat-button-toggle style="width: 50%;" [value]="GENDER.MALE">Male</mat-button-toggle>
                <mat-button-toggle style="width: 50%;" [value]="GENDER.FEMALE">Female</mat-button-toggle>
              </mat-button-toggle-group>
              @if(formRegister.get('gender')?.hasError){
                <mat-error style="font-family: Montserrat;">
                  {{getError('gender')}}
                </mat-error>
              }
            </mat-form-field>
          </div>
          <div style="width: 48%; min-width: 183px; flex-grow:1;">
            <mat-form-field style="width: 100%;">
              <mat-label>Nationality</mat-label>
              <input formControlName="nationality" type="text" matInput  />
              @if(formRegister.get('nationality')?.hasError){
                <mat-error style="font-family: Montserrat;">
                  {{getError('nationality')}}
                </mat-error>
              }
            </mat-form-field>
            <mat-form-field style="width: 100%;">
              <mat-label>Phone</mat-label>
              <input formControlName="phone" type="tel" matInput  />
              @if(formRegister.get('phone')?.hasError){
                <mat-error style="font-family: Montserrat;">
                  {{getError('phone')}}
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <mat-form-field style="width: 100%;">
          <mat-label>Email</mat-label>
          <input formControlName="email" type="email" matInput  />
          @if(formRegister.get('email')?.hasError){
            <mat-error style="font-family: Montserrat;">
              {{getError('email')}}
            </mat-error>
          }
        </mat-form-field>
        <div style="display: flex; justify-content: space-between; flex-wrap: wrap; gap: 1em;">
          <mat-form-field style="width: 48%; flex-grow:1;">
            <mat-label>Password</mat-label>
            <input formControlName="password" type="password" matInput  />
            @if(formRegister.get('password')?.hasError){
              <mat-error style="font-family: Montserrat;">
                {{getError('password')}}
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field style="width: 48%; flex-grow:1;">
            <mat-label>Repeat Password</mat-label>
            <input formControlName="repeat_password" type="password" matInput  />
            @if(formRegister.get('repeat_password')?.hasError){
              <mat-error style="font-family: Montserrat;">
                {{getError('repeat_password')}}
              </mat-error>
            }
          </mat-form-field>
        </div>

        <mat-form-field class="gender-form-field" style="display: flex; flex-wrap: wrap; gap: 4px; align-items: center; width: 100%; color: #415273;">
          <input formControlName="terms" matInput  hidden/>
          <mat-checkbox formControlName="terms" color="primary">I agree with your</mat-checkbox>
          <a href="/privacy-notice" target="_blank" style="text-decoration: none; color: #db332a; cursor: pointer; font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
            Terms & Conditions
          </a>
          @if(formRegister.get('terms')?.hasError){
            <mat-error style="font-family: Montserrat;">
              {{getError('terms')}}
            </mat-error>
          }
        </mat-form-field>
        <ng-content>
          
        </ng-content>
      </form>
    }
    
    

